









































































































































































































































































































































































































import Vue from 'vue'
import { mapState } from 'vuex'
import { ApiResponse, InventoryItem, ItemFile } from '@/interfaces'
import { Cropper } from 'vue-advanced-cropper'
import FreeIcon from '@/components/Icons/FreeIcon.vue'
import SquareIcon from '@/components/Icons/SquareIcon.vue'
import LandscapeIcon from '@/components/Icons/LanscapeIcon.vue'
import FourThreeIcon from '@/components/Icons/FourThreeIcon.vue'

import 'vue-advanced-cropper/dist/style.css'
import { ElForm } from 'element-ui/types/form'

import { MessageBoxInputData } from 'element-ui/types/message-box'

interface ImageURL {
  id: number
  position: number
  url: string
  thumbnail: string
  token: string
  fallbackUrl: string
  fallbackThumbnail: string
}

interface ItemDataObject {
  position?: number | null
  description?: string | null
  location?: string | null
  place?: string | null
  rubriqueKey?: string | null
  value1?: number | null
  value2?: number | null
  value3?: number | null
  highlight?: boolean | null
  notes?: string | null
  sticker?: number | null
  metadata?: any | null
}

interface DataToSend {
  filename: string
  position?: number | null
  data?: string | null
  token?: string | null
  itemData?: ItemDataObject | null
}

interface HTMLInputEvent extends Event {
  target: HTMLInputElement & EventTarget
}

interface Boundaries {
  imageSize: {
    width: number
    height: number
  }

  visibleArea: {
    width: number
    height: number
  }
}

export default Vue.extend({
  components: { Cropper, SquareIcon, LandscapeIcon, FourThreeIcon, FreeIcon },
  props: {
    item: {
      type: Object as () => InventoryItem,
      required: false,
      default: () => {
        return {
          id: null,
          position: null,
          inventoryItemFiles: [] as ItemFile[],
          highlight: false,
        }
      },
    },
    isDisabled: Boolean, 
    saveTrigger: Boolean,
    resetChange: Boolean,
  },
  data() {
    return {
      addons: 0,
      minImagePosition: 0,
      busy: false,
      itemData: Object.assign(
        {},
        this.item ?? { position: null, value1: null, value2: null, value3: null, highlight: null }
      ) as InventoryItem,
      rubriques: [],
      itemFiles: [] as ItemFile[],
      itemFilesInfo: [] as ImageURL[],
      itemFilesLength: 0,
      itemFilesUrl: [] as string[],
      imageFile: '',
      viewportWidth: 0,
      placesList: [] as Record<string, string>[],
      locationsList: [] as Record<string, string>[],
      changeDetected: null as null | boolean,
      cropperImage: {
        src: null as string | null,
        type: null as string | null,
      },
      cropperAspectRatio: 1 / 1,
      imgLoaded: false,
      imgFilename: null as string | null,
      imgEdit: {
        url: null as string | null,
        token: null as string | null,
        position: null as string | null,
      },
      percent: 0,
      filesToProcess: 0,
      processedFiles: 0,
      status: '',
      showProgressModal: false,
      modalMessage: '',
      currentUploadedFiles: 1,
      formRules: {
        sticker: [{ pattern: /^[0-9 ]+$/, message: 'Veuillez renseigner des chiffres', trigger: 'change' }],
      },
      filesToUpload: [] as File[],
      fileIndexToUpload: null as null | number,
      itemCreated: 0,
      tmsPassword: null as null | string,
      vehicle: {
        vin: null as string | null,
        immatriculation: null as string | null,
        owner: null as string | null,
        color: null as string | null,
        fuelType: null as string | null,
        brand: null as string | null,
        dmec: null as string | null,
        gage: null as boolean | null,
      },
    }
  },
  computed: {
    ...mapState(['user']),
  },
  watch: {
    item: {
      deep: true,
      immediate: true,
      handler(newVal) {
        this.itemData = Object.assign({}, newVal ?? {})

        if (newVal.metadata) {
          this.vehicle = Object.assign({}, newVal.metadata ?? {})
        }
        else {
          this.resetVehicle()
        }

        // Update places and locations lists for auto-complete
        this.getPlacesList()
        this.getLocationsList()
      },
    },

    user(newVal) {
      this.rubriques = newVal.client.config.rubriques
      this.addons = newVal.client?.addons ?? 0
    },

    itemData: {
      deep: true,
      handler(newVal) {
        if (newVal.inventoryItemFiles !== undefined) {
          this.itemFiles = []
          this.itemData.inventoryItemFiles.forEach((file) => {
            this.itemFiles.push(file)
          })
        }

        if (this.changeDetected === false) {
          this.changeDetected = true
        }

        if (this.changeDetected === null) {
          this.changeDetected = false
        }
      },
    },

    itemFiles: {
      deep: true,
      immediate: true,
      handler(newVal) {
        //console.log("itemfile watcher")
        this.itemFilesInfo = this.sortByPosition(this.getFiles('image'))
        this.itemFilesLength = newVal.length
        this.itemFilesUrl = []
        this.itemFilesInfo.forEach((image: ImageURL) => {
          this.itemFilesUrl.push(image.url)
        })

        if (this.changeDetected === false) {
          this.changeDetected = true
        }

        if (this.changeDetected === null) {
          this.changeDetected = false
        }          
      },
    },
    saveTrigger() {
      this.submit()
    },
    resetChange() {
      this.resetChangeDetected()
    },
    fileIndexToUpload(newVal) {
      if (newVal !== null && this.filesToUpload.length > 0 && newVal <= this.filesToUpload.length) {
        this.uploadFile(this.filesToUpload[newVal], this.itemFilesLength + 1)
      }
    },
  },
  mounted() {
    if (this.user) {
      this.rubriques = this.user.client.config.rubriques
    }

    if (this.item) {
      this.itemFiles = []
      this.itemData = Object.assign({}, this.item)
      this.itemData.inventoryItemFiles.forEach((file) => {
        this.itemFiles.push(file)
      })
      this.itemFilesInfo = this.sortByPosition(this.getFiles('image'))
      this.itemFilesLength = this.itemFiles.length
      this.itemFilesUrl = []
      this.itemFilesInfo.forEach((image: ImageURL) => {
        this.itemFilesUrl.push(image.url)
      })
    }

    // Initiate places and locations lists for auto-complete
    this.getPlacesList()
    this.getLocationsList()

    this.viewportWidth = window.innerWidth

    window.addEventListener('resize', this.onResize)

    this.addons = this.user.client?.addons ?? 0
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize)
  },
  methods: {
    onResize() {
      this.viewportWidth = window.innerWidth
    },
    resetForm() {
      this.itemData = {
        position: null,
        description: null,
        location: null,
        place: null,
        rubriqueKey: null,
        value1: null,
        value2: null,
        value3: null,
        inventoryItemFiles: [],
        notes: null,
        sticker: null,
      }
      this.resetVehicle()
    },
    resetVehicle() {
      this.vehicle = {
        vin: null,
        immatriculation: null,
        owner: null,
        color: null,
        fuelType: null,
        brand: null,
        dmec: null,
        gage: null,
      }
    },
    cancelForm() {
      // if (this.modal !== true) {
      //   this.$router.push({
      //     name: 'inventory_edit',
      //     params: { projectId: this.$route.params.projectId, inventoryId: this.$route.params.inventoryId },
      //   })
      // }
      let refeshList = false
      if (this.itemCreated > 0) {
        refeshList = true
      }
      this.itemCreated = 0
      this.changeDetected = null    
      this.$emit('closeAddInventoryItemForm', refeshList)
    },
    submit(createAgain = false) {
      // appelé sur bouton enregistré et sur click en dehors de la fenetre ainsi que sur X de fermeture
      if (this.changeDetected == true) {
        ;(this.$refs.myForm as ElForm).validate((valid: boolean) => {
          if (valid) {
            const data = this.createDataToSend()
            if (this.item) {
              // Edit
              this.busy = true
              this.$api
                .put(`/inventory/${this.$route.params.inventoryId}/item/${this.item.id}`, data)
                .then((response) => {
                  const apiResponse = response.data as ApiResponse

                  this.$notify({
                    type: 'success',
                    title: 'Succès',
                    message: 'Lot modifié avec succès !',
                  })

                  this.itemData = apiResponse.data
                  this.$emit('itemUpdated', apiResponse.data)
                })
                .finally(() => {
                  this.busy = false
                  this.changeDetected = false
                })
            } else {
              // Store
              this.busy = true
              this.$api
                .post(`/inventory/${this.$route.params.inventoryId}/item`, data)
                .then((response) => {
                  const apiResponse = response.data as ApiResponse

                  this.$notify({
                    type: 'success',
                    title: 'Succès',
                    message: 'Lot créé avec succès !',
                  })

                  if (createAgain) {
                     // vider le form
                     this.resetForm()
                     this.itemCreated++
                  }
                  else {
                    // charger avec le retour de l'api
                    this.itemData = apiResponse.data
                  }

                  this.$emit('itemCreated', createAgain)
                })
                .catch((error) => {
                  if (error.response) {
                    const apiResponse = error.response.data as ApiResponse

                    this.$notify({
                      type: 'error',
                      title: 'Erreur',
                      message:
                        apiResponse.message ??
                        'Une erreur est survenue. Veuillez nous excuser pour la gêne occasionnée.',
                    })
                  }
                })
                .finally(() => {
                  this.busy = false
                  this.changeDetected = null
                  //this.resetForm()
                })
            }
          } else {
            this.$notify({
              type: 'error',
              title: 'Erreur',
              message: "Veuillez uniquement renseigner des chiffres pour l'étiquette.",
            })
            return false
          }
        })
      }
      else {
        // Pas de modif au submit
        // si appel depuis list (sur fermeture de pupop via handleClose / saveTrigger)
        // on vérifie si on est pas eu une serie de création, si oui on ferme popup + lancement refresh
        if (this.itemCreated > 0) {
          this.cancelForm()
        }
      }
    },
    resetChangeDetected() {
      this.changeDetected = false
    },
    getLastRubrique(rubriqueKey: any) { // TO Finish / To test
      let lastRubriqueItem
      if (Array.isArray(rubriqueKey)) {
        lastRubriqueItem = rubriqueKey[rubriqueKey.length - 1]
      } else {
        lastRubriqueItem = rubriqueKey
      }
      return lastRubriqueItem
    },
    createDataToSend(): ItemDataObject {
      // let lastRubriqueItem
      // if (Array.isArray(this.itemData.rubriqueKey)) {
      //   lastRubriqueItem = this.itemData.rubriqueKey[this.itemData.rubriqueKey.length - 1]
      // } else {
      //   lastRubriqueItem = this.itemData.rubriqueKey
      // }
      let stickerValue = null
      if (this.itemData?.sticker && typeof this.itemData?.sticker === 'number') {
        stickerValue = this.itemData.sticker
      }
      // vehicle
      let metadata // = [] as string[]
      if (this.vehicle.vin) {
        //metadata.push(this.vehicle.vin)
        metadata = this.vehicle
      }
      return {
        position: this.itemData.position ? parseInt(this.itemData.position) : null,
        description: this.itemData.description,
        location: this.itemData.location,
        place: this.itemData.place,
        rubriqueKey: this.getLastRubrique(this.itemData.rubriqueKey) ?? null,
        value1: parseFloat(this.itemData.value1 ?? '') ?? null,
        value2: parseFloat(this.itemData.value2 ?? '') ?? null,
        value3: parseFloat(this.itemData.value3 ?? '') ?? null,
        highlight: this.itemData.highlight ?? null,
        notes: this.itemData.notes ?? null,
        sticker: stickerValue ?? null,
        metadata: metadata ?? null,
      }
    },
    formatDate(str: string | null): string {
      if (str) {
        const date = Date.parse(str)
        if (!isNaN(date)) {
          return new Intl.DateTimeFormat('fr-FR', { dateStyle: 'medium', timeStyle: 'short' }).format(date)
        } else {
          return '-'
        }
      } else {
        return '-'
      }
    },
    getFiles(type: string): ImageURL[] {
      let items = [] as ImageURL[]
      this.itemFiles.forEach((itemFile: ItemFile) => {
        if (itemFile.type === type && itemFile.deletedAt == null) {
          items.push({
            id: itemFile.id,
            position: itemFile.position,
            url: itemFile.url,
            thumbnail: itemFile.urlThumb,
            token: `${itemFile.accessToken}`,
            fallbackUrl: `${process.env.VUE_APP_API_URL}/file/${itemFile.accessToken}/full`,
            fallbackThumbnail: `${process.env.VUE_APP_API_URL}/file/${itemFile.accessToken}/thumbnail`,
          })
        }
      })

      return items
    },
    sortByPosition(files: ImageURL[]) {
      return files.sort((file1, file2) => {
        if (file1.position > file2.position) {
          return 1
        }

        if (file1.position < file2.position) {
          return -1
        }

        return 0
      })
    },
    changeImagePosition(image: ImageURL, newPosition: number) {
      const lastPosition = image.position

      const data = {
        files: this.itemFilesInfo,
        imageId: image.id,
        newPosition,
        lastPosition,
        itemData: this.createDataToSend(),
      }

      this.$api
        .put(`/inventoryitem/${this.item.id}/file`, data)
        .then((response) => {
          const apiResponse = response.data as ApiResponse

          this.$notify({
            type: 'success',
            title: 'Succès',
            message: 'Image mise à jour avec succès !',
          })

          // Pas de mise à jour sur la liste principale : uniqument mise à jour en local de la liste des photos
          //this.$emit('itemUpdated', apiResponse.data.item, false)
          this.itemFiles = apiResponse.data.files // Todo normaliser la réponse on retroune que les photos sur une modif de photo... pas les photos dans files...
        })
        .catch((error) => {
          if (error.response) {
            const apiResponse = error.response.data as ApiResponse

            this.$notify({
              type: 'error',
              title: 'Erreur',
              message:
                apiResponse.message ?? 'Une erreur est survenue. Veuillez nous excuser pour la gêne occasionnée.',
            })
          }
        })
        .finally(() => {
          this.busy = false
        })
    },
    deleteFile(image: ImageURL) {
      this.$confirm(
        'Êtes-vous sûr(e) de vouloir supprimer ce fichier ? Attention, cette opération est irréversible.',
        'Confirmation',
        {
          confirmButtonText: 'OK',
          cancelButtonText: 'Annuler',
          type: 'warning',
        }
      )
        .then(() => {
          this.busy = true
          this.$api.delete(`/inventory/file/${image.id}`).then((response) => {
            const apiResponse = response.data as ApiResponse

            this.$notify({
              type: 'success',
              title: 'Succès',
              message: 'Opération réalisée avec succès !',
            })

            // Pas de mise à jour sur la liste principale : uniqument mise à jour en local de la liste des photos
            //this.$emit('itemFileUpdated', { item: this.item, itemFiles: apiResponse.data })
            this.itemFiles = apiResponse.data
          })
        })
        .catch((error) => {
          if (error !== 'cancel') {
            this.$notify({
              type: 'error',
              title: 'Erreur',
              message: 'Une erreur est survenue. Veuillez nous excuser pour la gêne occasionnée.',
            })
          }
        })
        .finally(() => {
          this.busy = false
        })
    },
    addFile() {
      document.getElementById('inputFile')?.click()
    },
    updateInputFile(event: Event) {
      const inputFiles = (event.target as HTMLInputElement).files
      if (!inputFiles) {
        this.$notify({
          type: 'error',
          title: 'Erreur',
          message: 'Une erreur est survenue. Veuillez nous excuser pour la gêne occasionnée.',
        })
        return
      }
      this.uploadFile(inputFiles[0])
    },
    uploadFile(file: File, position = -1) {
      if (position === -1) {
        position = this.itemFilesLength + 1
      }
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.addEventListener(
        'load',
        () => {
          let dataurl = ''
          if (reader.result) {
            const canvas = document.createElement('canvas')
            // Creating a canvas to resize later
            let img = document.createElement('img')
            let ctx = canvas.getContext('2d')
            img.src = reader.result?.toString() ?? ''

            img.onload = () => {
              canvas.width = img.width
              canvas.height = img.height
              ctx?.drawImage(img, 0, 0, img.width, img.height)

              let MAX_WIDTH = 2048
              let MAX_HEIGHT = 2048
              let width = img.width
              let height = img.height

              if (width > height) {
                if (width > MAX_WIDTH) {
                  height *= MAX_WIDTH / width
                  width = MAX_WIDTH
                }
              } else {
                if (height > MAX_HEIGHT) {
                  width *= MAX_HEIGHT / height
                  height = MAX_HEIGHT
                }
              }

              // Clearing canvas...
              ctx?.clearRect(0, 0, canvas.width, canvas.height)

              // ...to refill it with the resized picture
              ctx = canvas.getContext('2d')
              canvas.width = width
              canvas.height = height
              ctx?.drawImage(img, 0, 0, width, height)

              dataurl = canvas.toDataURL('image/jpeg', 0.75).toString().split(',')[1]

              const data = {
                filename: file.name,
                position,
                data: dataurl,
              } as DataToSend

              this.sendFile(data)
            }
          }
        },
        false
      )
    },
    sendFile(data: DataToSend) {
      this.busy = true

      let action = null

      const itemData = this.createDataToSend()
      data = {
        ...data,
        itemData,
      } as DataToSend

      if (this.imgEdit.url !== null) {
        // modify image
        action = this.$api.put(`/inventoryitem/${this.item.id}/file`, data)
      } else {
        // Add new image
        action = this.$api.post(`/inventoryitem/${this.item.id}/file`, data)
      }

      action
        .then((response) => {
          const apiResponse = response.data as ApiResponse

          let message = ''
          if (this.imgEdit.url !== null) {
            message = 'Image modifiée avec succès !'
          } else {
            message = 'Image ajoutée avec succès !'
          }

          this.$notify({
            type: 'success',
            title: 'Succès',
            message,
          })

          //this.$emit('itemUpdated', apiResponse.data.item, false)
          if (this.imgEdit.url !== null) {
            // this.itemFiles.forEach((item) => {
            //   apiResponse.data.forEach((data: ItemFile) => {
            //     if (item.id === data.id) {
            //       this.$set(item, 'accessToken', data.accessToken)
            //       this.$set(item, 'deletedAt', data.deletedAt)
            //       this.$set(item, 'fileFullPath', data.fileFullPath)
            //       this.$set(item, 'filename', data.filename)
            //       this.$set(item, 'id', data.id)
            //       this.$set(item, 'position', data.position)
            //       this.$set(item, 'type', data.type)
            //     }
            //   })
            // })
            this.itemFiles = apiResponse.data.files
          } else {
            // La mise à jour le la liste principale déclenche un watcher sur l'item qui reset le formulaire avec les données locales :/
            // on met a jour la liste principale pour ajouter les photos, ce qui est a priori inutile car 
            // a la fermeture de la popop on relance tous les appels api. Mais en fait on malgré l'apel api de la liste des items on ne recharge pas les photos dans la liste principale
            // watcher = perte de maitrise, a n'utiliser que si indispensable
            // ==> décision : 
            // 1) on ne remet pas a jour une donnée d'un lot dans la liste partiellement On ne remet a jout la list que une fois a la fermeture de la popup
            // 2) la liste etant rechargée totalement par api a la fermeture de le popup c uinutile pour l'instant de refaire une maj partielle 
            // this.$emit('itemFileAdded', {
            //   itemId: this.item.id,
            //   file: apiResponse.data,
            // })

            // mise à jour de la liste des photos  en front dans la popup sur retour api
            this.itemFiles.push(apiResponse.data)

            // Increasing index to upload
            if (this.fileIndexToUpload == this.filesToUpload.length - 1) {
              this.fileIndexToUpload = null
            } else {
              if (this.fileIndexToUpload !== null) {
                this.fileIndexToUpload++
              }
            }
          }
        })
        .catch((error) => {
          if (error.response) {
            const apiResponse = error.response.data as ApiResponse

            this.$notify({
              type: 'error',
              title: 'Erreur',
              message:
                apiResponse.message ?? 'Une erreur est survenue. Veuillez nous excuser pour la gêne occasionnée.',
            })
          }
        })
        .finally(() => {
          this.busy = false
          this.imgLoaded = false
          this.imgFilename = null
          this.imgEdit = {
            url: null,
            token: null,
            position: null,
          }
          this.processedFiles += 1
          this.percent = Math.round((this.processedFiles / this.filesToProcess) * 100)

          if (this.processedFiles == this.filesToProcess) {
            this.status = 'success'
            setTimeout(() => {
              this.showProgressModal = false
            }, 1000)
          }
        })
    },
    cancelCrop() {
      ;(this.$refs.cropper as Cropper).reset()
      this.imgLoaded = false
      this.imgFilename = null
      this.imgEdit = {
        url: null,
        token: null,
        position: null,
      }
    },
    crop() {
      let cropper = null

      if (this.imgEdit.url !== null) {
        cropper = this.$refs.cropperEdit as Cropper
      } else {
        cropper = this.$refs.cropper as Cropper
      }

      const { canvas } = cropper.getResult()

      if (canvas) {
        canvas.toBlob((blob) => {
          if (blob !== null) {
            const reader = new FileReader()
            reader.readAsDataURL(blob)
            reader.onloadend = () => {
              if (reader.result) {
                // Creating a canvas to resize later
                let img = document.createElement('img')
                let ctx = canvas.getContext('2d')
                img.src = reader.result?.toString() ?? ''
                ctx?.drawImage(img, 0, 0)

                // This image gives us the width/height of the cropped area
                let i = new Image()
                i.src = img.src

                i.onload = () => {
                  let MAX_WIDTH = 2048
                  let MAX_HEIGHT = 2048
                  let width = i.width
                  let height = i.height

                  if (width > height) {
                    if (width > MAX_WIDTH) {
                      height *= MAX_WIDTH / width
                      width = MAX_WIDTH
                    }
                  } else {
                    if (height > MAX_HEIGHT) {
                      width *= MAX_HEIGHT / height
                      height = MAX_HEIGHT
                    }
                  }

                  // Clearing canvas...
                  ctx?.clearRect(0, 0, canvas.width, canvas.height)

                  // ...to refill it with the resized picture
                  ctx = canvas.getContext('2d')
                  canvas.width = width
                  canvas.height = height
                  ctx?.drawImage(img, 0, 0, width, height)

                  let dataurl = canvas.toDataURL('image/jpeg', 0.75)

                  // Sending file
                  const data = {
                    filename: this.imgFilename ?? 'image.jpg',
                    position: this.imgEdit.position !== null ? this.imgEdit.position : this.itemFilesLength + 1,
                    data: dataurl.toString().split(',')[1],
                    token: this.imgEdit.token,
                  } as DataToSend
                  this.sendFile(data)
                }
              }
            }
          }
        }, this.cropperImage.type ?? 'image/jpg')
      }
    },
    reset() {
      this.cropperImage = {
        src: null,
        type: null,
      }

      this.imgEdit = {
        url: null,
        token: null,
        position: null,
      }
    },
    loadImage(event: HTMLInputEvent) {
      // Ensuring we have a file before attempting to read it
      this.resetProgress()
      if (event.target && event.target.files) {
        this.filesToProcess = event.target.files.length

        if (this.filesToProcess > 1) {
          this.showProgressModal = true
        }

        let filesToUpload = [] as File[]
        Array.from(event.target.files).forEach((file) => {
          // Revoking the object URL, to allow the garbage collector to destroy the uploaded before file
          if (this.cropperImage.src) {
            URL.revokeObjectURL(this.cropperImage.src)
          }

          filesToUpload.push(file)
          this.currentUploadedFiles++
        })
        this.filesToUpload = filesToUpload
        this.fileIndexToUpload = 0
        this.currentUploadedFiles = 1
      }
    },
    getMimeType(file: ArrayBuffer, fallback = null as string | null) {
      const byteArray = new Uint8Array(file).subarray(0, 4)
      let header = ''
      for (let i = 0; i < byteArray.length; i++) {
        header += byteArray[i].toString(16)
      }
      switch (header) {
        case '89504e47':
          return 'image/png'
        case '47494638':
          return 'image/gif'
        case 'ffd8ffe0':
        case 'ffd8ffe1':
        case 'ffd8ffe2':
        case 'ffd8ffe3':
        case 'ffd8ffe8':
          return 'image/jpeg'
        default:
          return fallback
      }
    },
    setAspectRatio(type: string) {
      switch (type) {
        case 'free':
          this.cropperAspectRatio = 0
          break

        case 'fourthree':
          this.cropperAspectRatio = 4 / 3
          break

        case 'square':
        default:
          this.cropperAspectRatio = 1 / 1
          break

        case 'portrait':
          this.cropperAspectRatio = 9 / 16
          break

        case 'landscape':
          this.cropperAspectRatio = 16 / 9
          break
      }
    },
    rotate() {
      if (this.imgEdit.url !== null) {
        ;(this.$refs.cropperEdit as Cropper).rotate(90)
      } else {
        ;(this.$refs.cropper as Cropper).rotate(90)
      }
    },
    editImage(image: Record<string, string>) {
      if (image.fallbackUrl) {
        this.imgLoaded = false
        this.cropperImage = {
          src: null,
          type: null,
        }

        this.imgEdit = {
          url: image.fallbackUrl,
          token: image.token,
          position: image.position,
        }
      }
    },
    defaultSize({ imageSize, visibleArea }: Boundaries) {
      return {
        width: (visibleArea || imageSize).width,
        height: (visibleArea || imageSize).height,
      }
    },
    setCropperZoom(int: number) {
      if (this.imgLoaded == true) {
        if (int > 0) {
          ;(this.$refs.cropper as Cropper).zoom(1.3)
        } else {
          ;(this.$refs.cropper as Cropper).zoom(0.7)
        }
      } else {
        if (int > 0) {
          ;(this.$refs.cropperEdit as Cropper).zoom(1.3)
        } else {
          ;(this.$refs.cropperEdit as Cropper).zoom(0.7)
        }
      }
    },
    dropFile(event: DragEvent) {
      this.resetProgress()
      let droppedFiles = (event.dataTransfer as DataTransfer).files

      if (!droppedFiles) {
        this.$notify({
          type: 'error',
          title: 'Erreur',
          message: 'Une erreur est survenue. Veuillez nous excuser pour la gêne occasionnée.',
        })
        return
      }

      const droppedFilesArray = [...droppedFiles]

      this.filesToProcess = droppedFilesArray.length

      if (this.filesToProcess > 1) {
        this.showProgressModal = true
      }

      this.modalMessage = 'Envoi des fichiers en cours...'

      this.filesToUpload = droppedFilesArray
      this.fileIndexToUpload = 0
    },
    resetProgress() {
      this.filesToProcess = 0
      this.processedFiles = 0
      this.percent = 0
      this.status = ''
    },
    getPlacesList(silent = false) {
      if (!silent) {
        this.busy = true
      }

      this.placesList = []

      return this.$api
        .get(`/inventory/${this.$route.params.inventoryId}/items/places`)
        .then((response) => {
          const apiResponse = response.data as ApiResponse

          apiResponse.data.forEach((place: string) => {
            this.placesList.push({ value: place })
          })
        })
        .finally(() => {
          this.busy = false
        })
    },
    getLocationsList(silent = false) {
      if (!silent) {
        this.busy = true
      }

      this.locationsList = []

      return this.$api
        .get(`/inventory/${this.$route.params.inventoryId}/items/locations`)
        .then((response) => {
          const apiResponse = response.data as ApiResponse

          apiResponse.data.forEach((location: string) => {
            this.locationsList.push({ value: location })
          })
        })
        .finally(() => {
          this.busy = false
        })
    },
    queryPlaceSearch(queryString: string, cb: CallableFunction) {
      this.getPlacesList(true).then(() => {
        const places = this.placesList
        const results = queryString ? places.filter(this.createFilter(queryString)) : places

        cb(results)
      })
    },
    queryLocationSearch(queryString: string, cb: CallableFunction) {
      this.getLocationsList(true).then(() => {
        const locations = this.locationsList
        const results = queryString ? locations.filter(this.createFilter(queryString)) : locations

        cb(results)
      })
    },
    createFilter(queryString: string) {
      return (str: Record<string, string>) => {
        return str.value?.toLowerCase().indexOf(queryString.toLowerCase()) === 0 ?? null
      }
    },
    shiftedImages(url: string) {
      if (this.itemFilesUrl[0] == url) {
        return this.itemFilesUrl
      } else {
        let num = 0
        let array = [] as string[]

        while (this.itemFilesUrl[num] !== url) {
          array = this.itemFilesUrl.slice(num + 1).concat(this.itemFilesUrl.slice(0, num + 1))
          num++
        }
        return array
      }
    },
    filterCategory(node: Record<string, string>, keyword: string) {
      return node.label.toLowerCase().includes(keyword.toLowerCase())
    },
    // Est ce un vehicule selon son code : attention le widget fournit, la liste des code dans l'arbo, il faut donc récupérer le dernier
    isRubriqueVehiculeRec(rubriques: any, str: string): string {
      let rub = '-'
      rubriques.forEach((rubrique: any) => {
        if (rubrique.value == str) {
          rub = rubrique.vehicule
        }
        if (rubrique.children && rub == '-') {
          rub = this.isRubriqueVehiculeRec(rubrique.children, str)
        }
      })
      return rub
    },
    handleDropdown(command: string) {
      switch (command) {
        case 'infos':
          this.sivRequest()
          break

        case 'ng':
          this.dsvRequest()
          break

        case 'desc':
          this.descriptionGenerator()
          break
      }
    },
    // isTmsAccessTokenValid() {
    //   const accessToken = localStorage.getItem('tms_access_token')
    //   const accessTokenValidity = Number.parseInt(localStorage.getItem('tms_access_token_validity') ?? '0')
    //   const now = Math.round(Date.now() / 1000)

    //   if (!accessToken || !accessTokenValidity || accessTokenValidity < now) {
    //     return false
    //   } else {
    //     return true
    //   }
    // },
    // getTmsToken() {
    //   return new Promise((resolve) => {
    //     if (!this.isTmsAccessTokenValid()) {
    //       this.$api
    //         .post('/tms/access_token', {
    //           inventoryItemId: this.item.id,
    //           tmsPassword: this.tmsPassword,
    //         })
    //         .then((response) => {
    //           const apiResponse = response.data as ApiResponse

    //           localStorage.setItem('tms_access_token', apiResponse.data.access_token)
    //           localStorage.setItem('tms_access_token_validity', apiResponse.data.access_token_validity)

    //           resolve(localStorage.getItem('tms_access_token'))
    //         })
    //         .catch(() => {
    //           this.$prompt('Merci de renseigner votre mot de passe TMS pour continuer', 'Votre mot de passe TMS', {
    //             confirmButtonText: 'OK',
    //             cancelButtonText: 'Annuler',
    //           }).then((res) => {
    //             const value = (res as MessageBoxInputData).value
    //             this.tmsPassword = value

    //             resolve(this.getTmsToken())
    //           })
    //         })
    //     } else {
    //       resolve(localStorage.getItem('tms_access_token'))
    //     }
    //   })
    // },
    sivRequest() {
      if (this.vehicle.immatriculation || this.vehicle.vin) {
        this.$confirm('Merci de confirmer la demande des informations du véhicule.', 'Confirmation', {
          confirmButtonText: 'OK',
          cancelButtonText: 'Annuler',
          type: 'warning',
        }).then(() => {
          //this.getTmsToken().then(() => {
            //this.showNotification = false
            this.busy = true
            this.$api
              .post('/tms/infosvehicule', {
                //tmsAccessToken: localStorage.getItem('tms_access_token') ?? null,
                password: this.tmsPassword,
                inventoryItem: this.item.id,
                immatriculation: this.vehicle.immatriculation,
                vin: this.vehicle.vin
              })
              .then((response) => {
                const apiResponse = response.data as ApiResponse

                const sivData = apiResponse.data

                // Filling infos from API
                this.vehicle.color = sivData.couleur
                // this.vehicle.fuelType = sivData.energie.toString().toLowerCase()
                this.vehicle.immatriculation = sivData.immat
                // this.vehicle.powerKw = Math.round(parseInt(sivData.puissch) * 1.36).toString()
                // this.vehicle.power = sivData.puissfisc
                this.vehicle.vin = sivData.vin
                // this.vehicle.genre = sivData.genre
                this.vehicle.brand = sivData.brand
                // this.vehicle.year = this.getYear(sivData.dmec)
                this.vehicle.dmec = sivData.dmec
                this.vehicle.fuelType = sivData.fuelType
                // this.vehicle.mineType = sivData.mineType
                // this.vehicle.body = sivData.body
                // this.vehicle.seats = sivData.seats

                // Saving item
                //this.submit()
              })
              .catch((error) => {
                if (error.response) {
                  if (error.response.status == 500) {
                    this.$notify({
                      type: 'error',
                      title: 'Erreur',
                      message: 'Le serveur TMS est indisponible. Merci de réessayer ultérieurement.',
                    })
                  }
                  else {
                    this.$prompt('Session TMS expirée. Merci de renseigner votre mot de passe TMS puis de lancer à nouveau la requête', 'Votre mot de passe TMS', {
                      confirmButtonText: 'OK',
                      cancelButtonText: 'Annuler',
                    }).then((res) => {
                      const value = (res as MessageBoxInputData).value
                      this.tmsPassword = value
                    })                      
                  }
                }
              })
              .finally(() => {
                this.busy = false
                this.$nextTick(() => {
                  this.submit()
                })
              })
          //})
        })
      } else {
        //this.showNotification = true
        //this.notification = {
        //  message: "Merci de préciser un numéro d'immatriculation.",
        //  type: 'error',
        //}
        this.$notify({
            type: 'error',
            title: 'Erreur',
            message: "Merci de préciser un numéro VIN ou une immatriculation.",
          })
      }
    },
    descriptionGenerator() {
      this.itemData.description = this.vehicle.brand + ' - ' + this.vehicle.dmec + ' - ' + this.vehicle.color + ' - ' + this.vehicle.fuelType  + ' - ' + this.vehicle.immatriculation + '\n' + this.itemData.description 
    },
    dsvRequest() {

        this.$confirm('Merci de confirmer la demande de situation du véhicule.', 'Confirmation', {
          confirmButtonText: 'OK',
          cancelButtonText: 'Annuler',
          type: 'warning',
        }).then(() => {
          //this.getTmsToken().then(() => {
          //  this.showNotification = false
            this.busy = true
            this.$api
              .post('/tms/dsv', {
                //tmsAccessToken: localStorage.getItem('tms_access_token') ?? null,
                inventoryItem: this.item.id,
                immatriculation: this.vehicle.immatriculation,
                vin: this.vehicle.vin,
                previousOwner: this.vehicle.owner,
              })
              .then((response) => {
                const apiResponse = response.data as ApiResponse

                if (apiResponse.data == null) {
                  this.$notify({
                    type: 'error',
                    title: 'Erreur',
                    message:
                      'Erreur: Impossible de récupérer la situation du véhicule. Merci de reéssayer plus tard.',
                  })
                }

                this.vehicle.gage = apiResponse.data
              })
              .catch((error) => {
                if (error.response) {
                  if (error.response.status == 500) {
                    this.$notify({
                      type: 'error',
                      title: 'Erreur',
                      message: 'Le serveur TMS est indisponible. Merci de réessayer ultérieurement.',
                    })
                  }
                  else {
                    this.$prompt('Session TMS expirée. Merci de renseigner votre mot de passe TMS puis de lancer à nouveau la requête', 'Votre mot de passe TMS', {
                      confirmButtonText: 'OK',
                      cancelButtonText: 'Annuler',
                    }).then((res) => {
                      const value = (res as MessageBoxInputData).value
                      this.tmsPassword = value
                    })                      
                  }
                }                })
              .finally(() => {
                this.busy = false
                //this.showNotification = false

                this.$nextTick(() => {
                  this.submit()
                })
              })
          //})
        })
    },
  },
})

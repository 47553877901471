





















































































































































































































import Vue from 'vue'
import EditItemsForm from '@/components/EditInventoryItemsForm.vue'
import InventoryItemForm from '@/components/InventoryItemForm.vue'
import HighlightIcon from '@/components/Icons/HighlightIcon.vue'
import { mapState } from 'vuex'
import { ApiResponse, Project, InventoryItem, ItemFile } from '@/interfaces'
import QuickNav from '@/components/QuickNav.vue'

interface Rubrique {
  label: string
  value: string
  children: Rubrique[]
}

interface DropdownAction {
  action: string
  item?: InventoryItem
  renumberBy?: string
  index?: number
}

interface FileList {
  itemId: number
  itemFiles: string[]
}

// interface FileUpdatedInfos {
//   item: ItemFile
//   itemFiles: ItemFile[]
// }

// interface FileAddedInfos {
//   itemId: number
//   file: ItemFile
// }

// interface TransferParams {
//   requisition: null
//   items: number[]
//   useStickerAsStockId?: boolean
//   checkIfStockIdExist?: boolean
// }

export default Vue.extend({
  components: { InventoryItemForm, EditItemsForm, HighlightIcon, QuickNav },
  props: {
    project: {
      type: Object as () => Project,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      addons: 0,
      busy: false,
      items: [] as InventoryItem[],
      itemsId: [] as number[],
      itemsChecked: [] as boolean[],
      checkAll: false,
      indeterminate: false,
      showAddInventoryItemModal: false,
      showTransferModal: false,
      showInvTransferModal: false,
      invProjects: [] as Project[],
      reqProjects: [] as Project[],
      itemsToEdit: [] as number[],
      showEditModal: false,
      transferTo: null,
      rubriques: [],
      itemEdit: null as InventoryItem | null,
      mediaList: [] as FileList[],
      listThumbnails: [] as FileList[],
      saveTrigger: false,
      resetChange: false,
      //isInventoryTransfer: true,
      useStickerAsStockId: true,
      checkIfStockIdExist: true,
      showCreateRequisitionModal: false,
      lastCheckedIndex: -1,
      checkAction: '',
      checkedItemsNumber: 0,
    }
  },
  computed: {
    ...mapState(['user']),
  },
  watch: {
    project(newVal) {
      this.fetchInventoryItems(newVal.inventory?.id)
    },
    user(newVal) {
      this.rubriques = newVal.client.config.rubriques
      this.addons = newVal.client?.addons ?? 0
    },
    items: {
      deep: true,
      immediate: true,
      handler() {
        if (!this.itemsChecked.every((item) => item === true) && !this.itemsChecked.every((item) => item === false)) {
          this.indeterminate = true
        } else {
          this.indeterminate = false
          if (this.itemsChecked.every((item) => item === true)) {
            this.checkAll = true
          }

          if (this.itemsChecked.every((item) => item === false)) {
            this.checkAll = false
          }
        }
      },
    },
  },
  mounted() {
    if (this.project.inventory?.id) {
      this.fetchInventoryItems(this.project.inventory?.id)
    }
    if (this.user) {
      this.rubriques = this.user.client.config.rubriques
    }
    this.addons = this.user.client?.addons ?? 0
  },
  methods: {
    fetchInventoryItems(id: number | null) {
      if (id) {
        const loading = this.$loading({
          target: '.items-container',
          text: 'Chargement des données...',
        })
        this.$api
          .get(`/inventory/${id}/items`)
          .then((response) => {
            const apiResponse = response.data as ApiResponse

            // Resetting arrays
            this.items = []
            this.itemsId = []
            this.itemsChecked = []
            this.mediaList = []
            this.listThumbnails = []

            apiResponse.data.forEach((item: InventoryItem) => {
              if (item.id) {
                const updatedItem = {
                  ...item,
                  isChecked: false,
                } as InventoryItem
                this.items.push(updatedItem)
                this.itemsId.push(item.id)
                this.itemsChecked.push(false)
                this.mediaList.push({
                  itemId: item.id,
                  itemFiles: this.getMedias(this.cleanInventoryItemFiles(updatedItem.inventoryItemFiles), 'image'),
                })
                this.listThumbnails.push({
                  itemId: item.id,
                  itemFiles: this.getThumbnails(
                    this.cleanInventoryItemFiles(updatedItem.inventoryItemFiles),
                    'image'
                  ),
                })
              }
            })
          })
          .catch(() => {
            this.$notify({
              type: 'error',
              title: 'Erreur',
              message: 'Une erreur est survenue. Veuillez nous excuser pour la gêne occasionnée.',
            })
          })
          .finally(() => {
            loading.close()
          })
      }
    },
    cleanInventoryItemFiles(files: ItemFile[]) {
      return files.filter(this.cleanDeletedFiles).sort(this.sortByPosition)
    },
    cleanDeletedFiles(file: ItemFile) {
      return file.deletedAt !== 'NULL'
    },
    sortByPosition(file1: ItemFile, file2: ItemFile) {
      if (file1.position > file2.position) {
        return 1
      }

      if (file1.position < file2.position) {
        return -1
      }

      return 0
    },
    toggleCheckAll(status: boolean) {
      this.indeterminate = false
      this.items.forEach((item: InventoryItem, i: number) => {
        this.items[i].isChecked = status
        this.itemsChecked[i] = status
      })

      if (!status) {
        this.itemsToEdit = []
        this.checkAll = false
      }
      this.checkedItemsNumber = this.itemsChecked.filter((checkedStatus: boolean) => checkedStatus)?.length
    },
    toggleChecked(item: InventoryItem, index: number, event: PointerEvent | null) {
      this.itemsChecked[index] = !this.itemsChecked[index]
      this.checkAction = this.itemsChecked[index] ? 'select' : 'deselect'

      if (event?.shiftKey && this.lastCheckedIndex !== -1 && this.checkAction === 'select') {
        let start = this.lastCheckedIndex
        let end = index - 1
        // can select top to bottom or bottom to top
        // always start with lowest value
        if (start > end) {
          start = index + 1
          end = this.lastCheckedIndex
        }

        for (let i = start; i <= end; i++) {
          const currentItem = item ?? null
          if (currentItem) {
            this.itemsChecked[i] = true
            this.items[i].isChecked = true
          }
        }
        window?.getSelection()?.removeAllRanges()
      }

      this.lastCheckedIndex = index
      item.isChecked = this.checkAction === 'select'
      this.checkedItemsNumber = this.itemsChecked.filter((checkedStatus: boolean) => checkedStatus)?.length

      if (this.itemsChecked.every((item) => !item)) {
        // Reset multiple selection data
        this.lastCheckedIndex = -1
        this.checkAction = ''
      }
    },
    editInventoryItem(item: InventoryItem) {
      this.itemEdit = item
      this.showAddInventoryItemModal = true
    },
    duplicateInventoryItem(item: InventoryItem, index: number) {
      if (item) {
        this.$confirm('Êtes-vous sûr(e) de vouloir dupliquer ce lot ?', 'Confirmation', {
          confirmButtonText: 'OK',
          cancelButtonText: 'Annuler',
          type: 'warning',
        }).then(() => {
          const loading = this.$loading({
            target: '.items-container',
            text: 'Duplication en cours...',
          })
          this.$api
            .post(`/inventory/${this.project.id}/item/${item.id}/duplicate`)
            .then((response) => {
              const apiResponse = response.data as ApiResponse

              const newItem = apiResponse.data as InventoryItem
              const newIndex = index + 1
              this.items.splice(newIndex, 0, newItem)
              if (newItem.id) {
                this.itemsId.splice(newIndex, 0, newItem.id)
                this.itemsChecked.splice(newIndex, 0, false)
                this.mediaList.splice(newIndex, 0, {
                  itemId: newItem.id,
                  itemFiles: this.getMedias(this.cleanInventoryItemFiles(newItem.inventoryItemFiles), 'image'),
                })
                this.listThumbnails.splice(newIndex, 0, {
                  itemId: newItem.id,
                  itemFiles: this.getThumbnails(this.cleanInventoryItemFiles(newItem.inventoryItemFiles), 'image'),
                })
              }
              this.$notify({
                type: 'success',
                title: 'Succès',
                message: 'Opération réalisée avec succès !',
              })
            })
            .catch(() => {
              this.$notify({
                type: 'error',
                title: 'Erreur',
                message: 'Une erreur est survenue. Merci de reéssayer plus tard.',
              })
            })
            .then(() => {
              loading.close()
            })
        })
      }
    },
    deleteInventoryItem(item: InventoryItem) {
      if (item) {
        this.$confirm(
          'Êtes-vous sûr(e) de vouloir supprimer ce lot ? Attention, cette opération est irréversible.',
          'Confirmation',
          {
            confirmButtonText: 'OK',
            cancelButtonText: 'Annuler',
            type: 'warning',
          }
        ).then(() => {
          this.$api
            .delete(`/inventory/${this.project.id}/item/${item.id}`)
            .then(() => {
              this.$notify({
                type: 'success',
                title: 'Succès',
                message: 'Opération réalisée avec succès !',
              })

              this.$emit('inventoryItemDeleted')

              this.fetchInventoryItems(this.project.inventory?.id ?? null)
            })
            .catch(() => {
              this.$notify({
                type: 'error',
                title: 'Erreur',
                message: 'Une erreur est survenue. Merci de reéssayer plus tard.',
              })
            })
        })
      }
    },
    deleteInventoryItems() {
      let items = [] as number[]

      for (let index = 0; index < this.itemsChecked.length; index++) {
        if (this.itemsChecked[index] === true) {
          items.push(this.items[index].id ?? 0)
        }
      }

      if (items.length > 0) {
        this.$confirm(
          'Êtes-vous sûr(e) de vouloir supprimer ce(s) lot(s) ? Attention, cette opération est irréversible.',
          'Confirmation',
          {
            confirmButtonText: 'OK',
            cancelButtonText: 'Annuler',
            type: 'warning',
          }
        ).then(() => {
          this.$api
            .delete(`/inventory/${this.project.id}/items`, {
              data: {
                items,
              },
            })
            .then(() => {
              this.$notify({
                type: 'success',
                title: 'Succès',
                message: 'Opération réalisée avec succès !',
              })

              this.$emit('inventoryItemDeleted')
              this.toggleCheckAll(false)

              this.fetchInventoryItems(this.project.inventory?.id ?? null)
            })
        })
      }
    },
    getMedias(files: ItemFile[], type: string) {
      let medias = [] as string[]
      for (let index = 0; index < files.length; index++) {
        const element = files[index]

        if (element.type == type && element.deletedAt == null) {
          medias.push(element.url)
        }
      }

      return medias
    },
    getMediaList(itemId: number) {
      const file = this.mediaList.find((file) => file.itemId === itemId)
      if (file) {
        return file.itemFiles
      }
      return []
    },
    setMediaList(itemId: number, files: ItemFile[]) {
      const medias = this.getMedias(this.cleanInventoryItemFiles(files), 'image')
      this.mediaList.map((file) => {
        if (file.itemId === itemId) {
          file.itemFiles = medias
        }
      })
    },
    getThumbnails(files: ItemFile[], type: string) {
      let medias = [] as string[]
      for (let index = 0; index < files.length; index++) {
        const element = files[index]
        if (element.type == type && element.deletedAt == null) {
          medias.push(element.urlThumb)
          return medias
        }
      }

      return medias
    },
    getMediaThumbnail(itemId: number) {
      const file = this.listThumbnails.find((file) => file.itemId === itemId)
      if (file && file.itemFiles[0]) {
        return file.itemFiles[0]
      }
      return ''
    },
    setThumbnailList(itemId: number, files: ItemFile[]) {
      const medias = this.getThumbnails(this.cleanInventoryItemFiles(files), 'image')
      this.listThumbnails.map((file) => {
        if (file.itemId === itemId) {
          file.itemFiles = medias
        }
      })
    },
    numMedia(itemId: number) {
      const medias = this.getMediaList(itemId)
      if (medias.length !== 0) {
        return medias.length
      }
      return 0
    },
    getEstimatePrice(item: InventoryItem) {
      let estimation = ''
      if (item.value1 !== null) {
        const value1 = item.value1 == '0' ? 'Mémoire' : item.value1 + '€'
        estimation += ' ' + value1
      }
      if (item.value2 !== null) {
        const value2 = item.value2 == '0' ? 'Mémoire' : item.value2 + '€'
        if (item.value1 !== null) {
          estimation += ' - '
        }
        estimation += value2
      }
      if (item.value3 !== null) {
        const value3 = item.value3 == '0' ? 'Mémoire' : item.value3 + '€'
        if (item.value1 !== null || item.value2 !== null) {
          estimation += ' - '
        }
        estimation += value3
      }
      return estimation
    },
    openInventoryItemModal() {
      this.itemEdit = null
      this.showAddInventoryItemModal = true
    },
    handleItemCommand(command: DropdownAction) {
      // Add new item
      if (command.action == 'add') {
        this.openInventoryItemModal()
      }

      // Edit item
      if (command.action == 'edit' && command.item) {
        this.editInventoryItem(command.item)
      }

      // Duplication item
      if (command.action == 'duplicate' && command.item && command.index != undefined) {
        this.duplicateInventoryItem(command.item, command.index)
      }

      // Delete item
      if (command.action == 'delete' && command.item) {
        this.deleteInventoryItem(command.item)
      }

      // Renumber items
      if (command.action == 'renumber') {
        this.renumber(command.renumberBy ?? null)
      }

      // Mass edit
      if (command.action == 'massEdit') {
        this.editInventoryItems()
      }

      // Mass delete
      if (command.action == 'massDelete') {
        this.deleteInventoryItems()
      }

      // Mass transfer to other inventory
      if (command.action == 'massInvTransfer') {
        this.showInvTransferModal = true
        //this.isInventoryTransfer = true
      }

      // Mass transfer to requisition
      if (command.action == 'massRequisTransfer') {
        this.showTransferModal = true
        //this.isInventoryTransfer = false
      }

      // Mass item transfer
      // if (command.action == 'massItemTransfer') {
      //   this.showCreateRequisitionModal = true
      // }

      // Download photos
      if (command.action == 'MassDownloadImages') {
        this.massDownloadImages()
      }
    },
    massDownloadImages() {
      if (!this.busy) {
        this.busy = true

        let items = [] as number[]
        for (let index = 0; index < this.itemsChecked.length; index++) {
          if (this.itemsChecked[index] === true) {
            items.push(this.items[index].id ?? 0)
          }
        }

        let success = false
        this.$api
          .post(`/file/mass-download-images/inventory`, {
            ids: items
          })
          .then((response) => {
            const apiResponse = response.data as ApiResponse

            if (apiResponse.success === true) {
              const documentUrl = `${process.env.VUE_APP_API_URL}/document/${apiResponse.data.filename}/download_once`
              window.open(documentUrl, '_blank')
            }
          })
          .catch((error) => {
            if (error.response) {
              const apiResponse = error.response.data as ApiResponse
              this.$notify({
                type: 'error',
                title: 'Erreur',
                message:
                  apiResponse.message ?? 'Une erreur est survenue. Veuillez nous excuser pour la gêne occasionnée.',
              })
            }
          })
          .finally(() => {
            this.busy = false
          })
      }
    },
    hideInventoryItemModal(refreshList: boolean) {
      // Si creation "a nouveau" on recharge pas la liste en fermant la popup
      if (refreshList) {
        this.$emit('inventoryItemAdded')
      }
      this.showAddInventoryItemModal = false
    },
    itemAdded(createAgain: boolean) {
      // Si creation a nouveau on ne recharge pas la liste, elle sera rechargée en fermant la popup
      if (!createAgain) {
        this.showAddInventoryItemModal = false
        this.$emit('inventoryItemAdded')
      }
    },
    itemUpdated() {
      //(updatedItem: InventoryItem, closeModal = true) {
      // this.items.forEach((item, index) => {
      //   if (updatedItem.id == item.id) {
      //     this.items[index] = updatedItem
      //     if (closeModal) {
      //       this.showAddInventoryItemModal = false
      //       this.$emit('inventoryItemUpdated')
      //     } else {
      //       this.itemEdit = updatedItem
      //       const itemId = item.id ?? -1
      //       if (itemId > -1) {
      //         this.setMediaList(itemId, this.items[index].inventoryItemFiles)
      //         this.setThumbnailList(itemId, this.items[index].inventoryItemFiles)
      //       }
      //     }
      //   }
      // })
      // uniquement recharger le projet, on ne fait plus d'update de l'item, trop de bug , trop complexe
      // une amélioration pourrait consister a charger uniqment la liste et pas tout le projet, mais risque d'impact non pris en compte sur la synthèse
      this.$emit('inventoryItemUpdated')
      this.showAddInventoryItemModal = false
    },
    itemDeleted() {
      this.$emit('inventoryItemDeleted')
      this.showAddInventoryItemModal = false
    },
    // pas de mise a jour partielle de l'item sinon effet de bord
    // itemFileAdded(fileAddedInfos: FileAddedInfos) {
    //   this.items.forEach((item, index) => {
    //     if (fileAddedInfos.itemId == item.id) {
    //       this.items[index].inventoryItemFiles.push(fileAddedInfos.file)
    //       this.setMediaList(item.id, this.items[index].inventoryItemFiles)
    //       this.setThumbnailList(item.id, this.items[index].inventoryItemFiles)
    //     }
    //   })
    // },
    // itemFileUpdated(fileUpdatedInfos: FileUpdatedInfos) {
    //   this.items.forEach((item, index) => {
    //     if (fileUpdatedInfos.item.id == item.id) {
    //       this.items[index].inventoryItemFiles = fileUpdatedInfos.itemFiles
    //       this.setMediaList(item.id, this.items[index].inventoryItemFiles)
    //       this.setThumbnailList(item.id, this.items[index].inventoryItemFiles)
    //     }
    //   })
    //},
    getProjectTitle(project: Project): string {
      if (project.requisition) {
        return `${project.generatedNumber ?? '-'} - ${project.requisition?.debiteur?.formName ?? '-'}`
      }
      if (project.inventory) {
        return `${project.generatedNumber ?? '-'} - ${project.inventory?.debiteur?.formName ?? '-'}`
      }
      return `${project.generatedNumber ?? '-'}`
    },
    searchInventories(query: string): Project[] {
      if (query.length >= 3) {
        this.invProjects = [] as Project[]
        this.$api
          .get(`/inventories`, {
            params: {
              search: query,
            },
          })
          .then((response) => {
            const apiResponse = response.data as ApiResponse

            apiResponse.data.forEach((project: Project) => {
              this.invProjects.push(project)
            })
          })
      }

      return this.invProjects
    },
    searchRequisitions(query: string): Project[] {
      if (query.length >= 3) {
        this.reqProjects = [] as Project[]
        this.$api
          .get(`/requisitions`, {
            params: {
              search: query,
            },
          })
          .then((response) => {
            const apiResponse = response.data as ApiResponse

            apiResponse.data.forEach((project: Project) => {
              this.reqProjects.push(project)
            })
          })
      }

      return this.reqProjects
    },
    transferToRequisition() {
      //if (this.transferTo !== null) {
      this.busy = true

      const loading = this.$loading({
        target: '#transferModal',
        text: 'Transfert en cours...',
      })

      let items = [] as number[]

      for (let index = 0; index < this.itemsChecked.length; index++) {
        if (this.itemsChecked[index]) {
          items.push(this.items[index].id ?? 0)
        }
      }

      let params = {
        requisition: this.transferTo,
        items,
        useStickerAsStockId: this.useStickerAsStockId,
        checkIfStockIdExist: this.checkIfStockIdExist,
      } //as TransferParams

      let url = `/inventory/${this.project.inventory?.id}/items/transfer/requisition`
      //if (!this.isInventoryTransfer) {
      // url = `${url}/requisition`
      // params = {
      //   ...params,
      //   useStickerAsStockId: this.useStickerAsStockId,
      //   checkIfStockIdExist: this.checkIfStockIdExist,
      // }
      //}

      this.$api
        .post(url, params)
        .then((response) => {
          const apiResponse = response.data as ApiResponse

          // this.$notify({
          //   type: 'success',
          //   title: 'Succès',
          //   message: 'Opération réalisée avec succès !',
          // })

          // if (this.isInventoryTransfer) {
          //   this.items = apiResponse.data
          //   this.$emit('inventoryItemsTransfered')
          // }
          this.toggleCheckAll(false)
        })
        .catch((error) => {
          if (error.response) {
            const apiResponse = error.response.data as ApiResponse

            let errorMessage = apiResponse.message
            if (apiResponse.data) {
              errorMessage = `${errorMessage} : ${apiResponse.data}`
            }

            this.$notify({
              type: 'error',
              title: 'Erreur',
              message: errorMessage ?? 'Une erreur est survenue. Veuillez nous excuser pour la gêne occasionnée.',
            })
          }
        })
        .finally(() => {
          this.showTransferModal = false
          this.transferTo = null
          //this.projects = []
          this.resetModalCheckbox()
          this.busy = false
          loading.close()
        })
      //}
    },
    transferToInventory() {
      //if (this.transferTo !== null) {
      this.busy = true

      const loading = this.$loading({
        target: '#invTransferModal',
        text: 'Transfert en cours...',
      })

      let items = [] as number[]

      for (let index = 0; index < this.itemsChecked.length; index++) {
        if (this.itemsChecked[index]) {
          items.push(this.items[index].id ?? 0)
        }
      }

      let params = {
        inventory: this.transferTo,
        items,
      } //as TransferParams

      let url = `/inventory/${this.project.inventory?.id}/items/transfer`

      this.$api
        .post(url, params)
        .then((response) => {
          const apiResponse = response.data as ApiResponse
          this.items = apiResponse.data
          this.$emit('inventoryItemsTransfered')
          this.toggleCheckAll(false)
        })
        .catch((error) => {
          if (error.response) {
            const apiResponse = error.response.data as ApiResponse

            let errorMessage = apiResponse.message
            if (apiResponse.data) {
              errorMessage = `${errorMessage} : ${apiResponse.data}`
            }

            this.$notify({
              type: 'error',
              title: 'Erreur',
              message: errorMessage ?? 'Une erreur est survenue. Veuillez nous excuser pour la gêne occasionnée.',
            })
          }
        })
        .finally(() => {
          this.showInvTransferModal = false
          this.transferTo = null
          //this.projects = []
          this.busy = false
          loading.close()
        })
      //}
    },
    closeTransferModal() {
      this.showInvTransferModal = false
      this.showTransferModal = false
      this.transferTo = null
      //this.projects = []
    },
    // makeRequisition() {
    //   this.busy = true

    //   const loading = this.$loading({
    //     target: '#createRequisitionModal',
    //     text: 'Création en cours...',
    //   })

    //   let items = [] as number[]

    //   for (let index = 0; index < this.itemsChecked.length; index++) {
    //     if (this.itemsChecked[index]) {
    //       items.push(this.items[index].id ?? 0)
    //     }
    //   }
    //   this.$api
    //     .post(`/inventory/${this.project.inventory?.id}/makerequisition`, {
    //       items,
    //       useStickerAsStockId: this.useStickerAsStockId,
    //       checkIfStockIdExist: this.checkIfStockIdExist,
    //     })
    //     .then((response) => {
    //       const apiResponse = response.data as ApiResponse

    //       this.$notify({
    //         type: 'success',
    //         title: 'Succès',
    //         message: 'Opération réalisée avec succès !',
    //       })

    //       this.$router.push({
    //         name: 'requisition_edit',
    //         params: {
    //           projectId: apiResponse.data.id,
    //           requisitionId: apiResponse.data.requisition?.id.toString() ?? '',
    //         },
    //       })
    //     })
    //     .catch((error) => {
    //       if (error.response) {
    //         const apiResponse = error.response.data as ApiResponse

    //         let errorMessage = apiResponse.message
    //         if (apiResponse.data) {
    //           errorMessage = `${errorMessage} : ${apiResponse.data}`
    //         }

    //         this.$notify({
    //           type: 'error',
    //           title: 'Erreur',
    //           message: errorMessage ?? 'Une erreur est survenue. Veuillez nous excuser pour la gêne occasionnée.',
    //         })
    //       }
    //     })
    //     .finally(() => {
    //       this.resetModalCheckbox()
    //       this.showCreateRequisitionModal = false
    //       this.busy = false
    //       loading.close()
    //     })
    // },
    renumber(choice: string | null) {
      let data = {
        orderBy: null as string | null,
      }

      switch (choice) {
        case 'place':
          data.orderBy = 'place'
          break

        case 'rubrique':
          data.orderBy = 'rubrique'
          break

        case 'date':
          data.orderBy = 'date'
          break
      }

      this.$api.post(`/inventory/${this.project.inventory?.id}/items/renumber`, data).then((response) => {
        const apiResponse = response.data as ApiResponse

        this.$notify({
          type: 'success',
          title: 'Succès',
          message: 'Inventaire renuméroté avec succès.',
        })

        this.items = apiResponse.data
      })
    },
    getRubriqueRec(rubriques: Rubrique[], str: string): string {
      let rub = '-'
      rubriques.forEach((rubrique: Rubrique) => {
        if (rubrique.value == str) {
          rub = rubrique.label
        }
        if (rubrique.children && rub == '-') {
          rub = this.getRubriqueRec(rubrique.children, str)
        }
      })

      return rub
    },
    handleClose(done: CallableFunction) {
      this.saveTrigger = !this.saveTrigger
      done()
    },
    resetChangeDetection() {
      // This event is used because the item change is not detected when a user opens the same inventory item twice
      this.resetChange = !this.resetChange
    },
    editInventoryItems() {
      let items = [] as number[]
      this.itemsToEdit = []

      for (let index = 0; index < this.itemsChecked.length; index++) {
        if (this.itemsChecked[index] === true) {
          items.push(this.items[index].id ?? 0)
        }
      }

      this.itemsToEdit = items

      if (items.length > 0) {
        this.showEditModal = true
      }
    },
    closeEditModal() {
      this.showEditModal = false
      this.indeterminate = false

      for (let i = 0; i < this.itemsChecked.length; i++) {
        this.itemsChecked[i] = false
      }

      this.itemsToEdit = []
      this.fetchInventoryItems(this.project.inventory?.id ?? null)
    },
    isHighlight(item: InventoryItem) {
      return item.highlight == true
    },
    resetModalCheckbox() {
      this.useStickerAsStockId = true
      this.checkIfStockIdExist = true
    },
  },
})
